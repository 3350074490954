import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import numeral from "numeral";
import { API_URL } from "../../constants/apiConfig";

const DetailStorePage = ({
  ownstore,
  onclose,
  onOwnStoreUpdated = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [EditedOwnStore, setEditedOwnStore] = useState(ownstore);
  const [showEditStoreForm, setShowEditStoreForm] = useState(false); // State for showing EditOwnStoreForm
  const [showTrfModal, setShowTrfModal] = useState(false);
  const [showTrfOutModal, setShowTrfOutModal] = useState(false);
  const [imageHeight, setImageHeight] = useState(window.innerHeight * 0.75);

  useEffect(() => {
    setEditedOwnStore(ownstore);
  }, [ownstore]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedOwnStore((prevOwnStore) => ({
      ...prevOwnStore,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/ownstore/${ownstore.id}`,
        EditedOwnStore
      );
      if (response.status === 200) {
        setIsEditing(false);
        onOwnStoreUpdated(); // Call the function to refetch data
      }
    } catch (error) {
      console.error("Gagal mengupdate ownstore:", error);
    }
  };

  const handleStoreUpdated = () => {
    setShowEditStoreForm(false);
  };

  const showTrfPhotoModal = () => {
    setShowTrfModal(true);
  };

  const hideTrfPhotoModal = () => {
    setShowTrfModal(false);
  };

  const showTrfOutPhotoModal = () => {
    setShowTrfOutModal(true);
  };

  const hideTrfOutPhotoModal = () => {
    setShowTrfOutModal(false);
  };

  return (
    <div className="overflow-auto max-h-[calc(100vh-100px)]">
      <div className="p-4 bg-white rounded-md shadow-md">
        {isEditing ? (
          <div className="">
            <h2 className="font-bold text-2xl mb-4">Edit Ownstore</h2>
            <div className="md:grid md:grid-cols-2 md:gap-4">
              <div className="mb-4">
                <label className="block font-semibold">Kontrak:</label>
                <input
                  type="text"
                  name="kontrak"
                  value={EditedOwnStore.kontrak}
                  onChange={handleInputChange}
                  className="border p-2 rounded w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block font-semibold">
                  Tanggal Bergabung:
                </label>
                <input
                  type="date"
                  name="joindate"
                  value={
                    EditedOwnStore.joindate
                      ? new Date(EditedOwnStore.joindate)
                          .toISOString()
                          .substr(0, 10)
                      : ""
                  }
                  onChange={handleInputChange}
                  className="border p-2 rounded w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block font-semibold">Tanggal Berhenti:</label>
                <input
                  type="date"
                  name="outdate"
                  value={
                    EditedOwnStore.outdate
                      ? new Date(EditedOwnStore.outdate)
                          .toISOString()
                          .substr(0, 10)
                      : ""
                  }
                  onChange={handleInputChange}
                  className="border p-2 rounded w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block font-semibold">Alasan Keluar:</label>
                <input
                  type="text"
                  name="reason"
                  value={EditedOwnStore.reason}
                  onChange={handleInputChange}
                  className="border p-2 rounded w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block font-semibold">Jumlah Slot:</label>
                <input
                  type="number"
                  name="slot"
                  value={EditedOwnStore.slot}
                  onChange={handleInputChange}
                  className="border p-2 rounded w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block font-semibold">Investasi:</label>
                <input
                  type="decimal"
                  name="investasi"
                  value={EditedOwnStore.investasi}
                  onChange={handleInputChange}
                  className="border p-2 rounded w-full"
                />
              </div>

              <div className="mb-4 col-span-2">
                <label className="block font-semibold">Informasi:</label>
                <input
                  type="text"
                  name="information"
                  value={EditedOwnStore.information}
                  onChange={handleInputChange}
                  className="border p-2 rounded w-full"
                />
              </div>

              <button
                onClick={handleSaveClick}
                className="bg-green-500 text-white px-4 py-2 mx-4 rounded hover:bg-green-600">
                Simpan
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-600">
                Batal Edit
              </button>
            </div>
          </div>
        ) : (
          <div className="space-y-2">
            <p className="font-semibold grid grid-cols-2">
              <strong>ID:</strong> {ownstore.id}
            </p>
            <p className="font-semibold grid grid-cols-2">
              <strong>Nama Store:</strong> {ownstore.store}
            </p>
            <p className="grid grid-cols-2">
              <strong>Nama Di Kontrak:</strong> {ownstore.name}
            </p>
            <p className="grid grid-cols-2">
              <strong>Nomor Kontrak:</strong> {ownstore.kontrak}
            </p>
            <p className="grid grid-cols-2">
              <strong>Bergabung:</strong>{" "}
              {ownstore.joindate
                ? new Date(ownstore.joindate)
                    .toISOString()
                    .substr(0, 10)
                    .replace(/-/g, "/")
                : ""}
            </p>
            <p className="grid grid-cols-2">
              <strong>Berhenti:</strong>{" "}
              {ownstore.outdate
                ? new Date(ownstore.outdate)
                    .toISOString()
                    .substr(0, 10)
                    .replace(/-/g, "/")
                : ""}
            </p>
            <p className="grid grid-cols-2">
              <strong>Alasan Berhenti:</strong> {ownstore.reason}
            </p>
            <p className="grid grid-cols-2">
              <strong>Jumlah Slot:</strong> {ownstore.slot}
            </p>
            <p className="grid grid-cols-2">
              <strong>Investasi:</strong>{" "}
              {numeral(ownstore.investasi).format(0, 0)}
            </p>
            <p className="grid grid-cols-2">
              <strong>Informasi:</strong> {ownstore.information}
            </p>
            <div className="flex justify-between">
              <button
                onClick={onclose}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400">
                Kembali
              </button>
              <button
                onClick={handleEditClick}
                className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600">
                Edit Ownstore
              </button>
            </div>
            <div className="flex justify-between">
              <button
                onClick={showTrfPhotoModal}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                {" "}
                Bukti Transfer
              </button>
              <button
                onClick={showTrfOutPhotoModal}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                Bukti Pencairan
              </button>
            </div>
          </div>
        )}
      </div>
      {showTrfModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-md max-w-md">
            <strong>Gambar Bukti Pembayaran:</strong>
            <img
              src={`${API_URL}/uploads/${ownstore.trfInUrl}`} // Use `ownstore` instead of `kontrak`
              alt="Foto Kontrak"
              className="mt-2 max-w-full"
              style={{ maxHeight: `${imageHeight}px` }}
            />
            <button
              onClick={hideTrfPhotoModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
              Tutup
            </button>
          </div>
        </div>
      )}
      {showTrfOutModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-md max-w-md">
            <strong>Gambar Bukti Pencairan:</strong>
            <img
              src={`${API_URL}/uploads/${ownstore.trfOutUrl}`} // Use `ownstore` instead of `kontrak`
              alt="Foto Pencairan"
              className="mt-2 max-w-full"
              style={{ maxHeight: `${imageHeight}px` }}
            />
            <button
              onClick={hideTrfOutPhotoModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
              Tutup
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailStorePage;
