import React, { useState, useEffect } from "react";
import axios from "axios";
import TambahMitraForm from "./TambahMitraForm";
import DetailMitra from "./DetailMitra";
import OwnStoreList from "./OwnStoreList";
import DeleteModal from "../../layouts/ui/DeleteModal";
import { FaPlus } from "react-icons/fa";
import { API_URL } from "../../constants/apiConfig";

const MitraList = () => {
  const [mitraData, setMitraData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedMitraToDelete, setSelectedMitraToDelete] = useState(null);
  const [showTambahForm, setShowTambahForm] = useState(false);
  const [selectedMitra, setSelectedMitra] = useState(null);
  const [filterText, setFilterText] = useState(""); // State untuk teks filter
  const [sortAscending, setSortAscending] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/mitra`);
      setMitraData(response.data);
    } catch (error) {
      console.error("Gagal mengambil data Mitra:", error);
    }
  };

  const handleSortClick = () => {
    const sortedData = [...mitraData].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) return sortAscending ? -1 : 1;
      if (nameA > nameB) return sortAscending ? 1 : -1;
      return 0;
    });

    setMitraData(sortedData);
    setSortAscending(!sortAscending);
  };

  const handleMitraAdded = () => {
    setShowTambahForm(false);
    fetchData();
  };

  const handleDetailClick = (mitra) => {
    setSelectedMitra(mitra);
  };

  const handleDeleteClick = (mitra) => {
    setSelectedMitraToDelete(mitra);
    setDeleteModalOpen(true);
  };

  // Delete the selected mitra
  const handleDeleteConfirm = async () => {
    if (selectedMitraToDelete) {
      try {
        const response = await axios.delete(
          `${API_URL}/mitra/${selectedMitraToDelete.id}`
        );
        if (response.status === 200) {
          setMitraData(
            mitraData.filter((mitra) => mitra.id !== selectedMitraToDelete.id)
          );
        }
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Gagal menghapus mitra:", error);
      }
    }
  };

  const handleMitraUpdated = () => {
    fetchData();
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-screen">
      <div
        className={`md:col-span-1 max-h-screen ${
          showTambahForm ? "hidden" : ""
        }`}>
        <div className="flex items-center justify-between mb-4">
          <h2 className="font-bold text-xl md:text-4xl">
            DATABASE
            <br className="md:hidden" />
            <span className="md:inline md:mr-2">&nbsp;</span>
            MITRA CRS
          </h2>
          <button
            onClick={() => setShowTambahForm(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-blue-600">
            <FaPlus />
          </button>
        </div>

        <div className="bg-slate-700 rounded-xl p-4 my-4">
          {/* Tambahkan class "flex-col" untuk mengatur tata letak menjadi kolom pada layar kecil */}
          <div className="flex flex-col sm:flex-row justify-between items-center">
            {/* Tambahkan class "mb-2" agar label pindah ke bawah pada layar kecil */}
            <h3 className="text-xl text-white mb-2 sm:mb-0">Search :</h3>
            <input
              type="text"
              value={filterText}
              onChange={handleFilterChange}
              className="px-2 py-1 border rounded-md focus:outline-none w-full md:w-64 text-center"
              placeholder="Cari berdasarkan Nama"
            />
          </div>
        </div>

        <div className="bg-gray-400 rounded-md overflow-auto max-h-60 md:max-h-[calc(100vh-200px)] custom-scrollbar">
          <table className="mt-4 w-full">
            <thead className="sticky top-0 bg-gray-400">
              <tr className="text-center text-sm md:text-base">
                <th onClick={handleSortClick} className="cursor-pointer">
                  Nama <span className="ml-1">{sortAscending ? "▼" : "▲"}</span>
                </th>
                <th>Kontak</th>
                <th>Kota</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mitraData
                .filter((mitra) =>
                  mitra.name.toLowerCase().includes(filterText.toLowerCase())
                )
                .map((mitra) => (
                  <tr
                    key={mitra.id}
                    onClick={() => handleDetailClick(mitra)} // Set selected mitra on click
                    className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600 text-gray-100 text-sm md:text-base">
                    <td className="p-4 text-xs md:text-base">{mitra.name}</td>
                    <td className="text-xs md:text-base">{mitra.kontak}</td>
                    <td className="text-xs md:text-base">{mitra.kota}</td>
                    <td>
                      <button
                        onClick={() => handleDeleteClick(mitra)} // Open delete modal
                        className="text-red-600 hover:text-red-800">
                        <svg
                          xmlns="https://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="md:col-span-1">
        {/* Show DetailMitra component if selectedMitra is not null */}
        {selectedMitra && !showTambahForm && !selectedMitra.isEditing && (
          <DetailMitra
            mitra={selectedMitra}
            onMitraUpdated={handleMitraUpdated}
          />
        )}
        {/* Show TambahMitraForm if showTambahForm is true */}
        {showTambahForm && (
          <TambahMitraForm
            onMitraAdded={handleMitraAdded}
            setShowTambahForm={setShowTambahForm}
          />
        )}
        {/* Show OwnStoreList if showOwnStoreList is true */}
        {!selectedMitra?.isEditing && selectedMitra && !showTambahForm && (
          <OwnStoreList selectedMitra={selectedMitra} />
        )}
        {/* Add DeleteModal component */}
        <DeleteModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={handleDeleteConfirm}
        />
      </div>
    </div>
  );
};

export default MitraList;
