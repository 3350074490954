import React, { useState, useEffect } from "react";
import axios from "axios";
import EditStoreModal from "./EditStoreModal";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import numeral from "numeral";
import { API_URL } from "../../constants/apiConfig";

const DetailStore = ({ store, onStoreUpdated = () => {} }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedStore, setEditedStore] = useState(store);
  const [totalInvestasi, setTotalInvestasi] = useState(0);

  useEffect(() => {
    setEditedStore(store);
  }, [store]);

  useEffect(() => {
    // Buat permintaan API ke tb_ownstore dengan idstore yang sesuai
    async function fetchTotalInvestasi() {
      try {
        const response = await axios.get(
          `${API_URL}/ownstore?idstore=${store.id}`
        );

        if (response.status === 200) {
          // Menghitung total investasi dari data yang sesuai
          let totalInvestasi = 0;
          response.data.forEach((ownStore) => {
            // Cek apakah idstore pada tb_ownstore sama dengan id store yang dipilih
            if (ownStore.idstore === store.id) {
              totalInvestasi += ownStore.investasi;
            }
          });

          setTotalInvestasi(totalInvestasi);
        }
      } catch (error) {
        console.error("Gagal mengambil data investasi:", error);
      }
    }

    fetchTotalInvestasi();
  }, [store.id]);

  useEffect(() => {
    setEditedStore(store);
  }, [store]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedStore((prevStore) => ({
      ...prevStore,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/store/${store.id}`,
        editedStore
      );
      if (response.status === 200) {
        setIsEditing(false);
        onStoreUpdated(); // Call the function to refetch data
      }
    } catch (error) {
      console.error("Gagal mengupdate store:", error);
    }
  };

  const shareViaWhatsApp = () => {
    const message = `Nama Store: ${store.name}\nId Store: ${store.id}\nTanggal Opening: ${store.opening}\nAlamat: ${store.alamat}\nKota: ${store.kota}\nInvestasi: ${store.investasi}\nInformasi: ${store.note}`;
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  const shareViaTelegram = () => {
    const message = `Nama Store: ${store.name}\nId Store: ${store.id}\nTanggal Opening: ${store.opening}\nAlamat: ${store.alamat}\nKota: ${store.kota}\nInvestasi: ${store.investasi}\nInformasi: ${store.note}`;
    const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(
      message
    )}`;
    window.open(telegramURL, "_blank");
  };

  return (
    <div className="overflow-auto max-h-[calc(100vh-100px)] p-4 rounded-md max-w-screen-md mb-4 shadow-md">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-bold text-xl md:text-4xl">
          Detail <br className="md:hidden" />{" "}
          <span className="md:inline md:mr-2">&nbsp;</span>
          Data Store
        </h2>
        <button
          onClick={handleEditClick}
          className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600">
          Edit Store
        </button>
      </div>

      {isEditing ? (
        <EditStoreModal
          editedStore={editedStore}
          handleInputChange={handleInputChange}
          handleSaveClick={handleSaveClick}
          handleCancelEdit={handleCancelEdit}
        />
      ) : (
        <div className="mt-4 md:grid md:grid-cols-2 text-sm">
          <p>
            <strong>Nama:</strong> {store.name}
          </p>
          <p>
            <strong>Id Store:</strong> {store.id}
          </p>
          <p>
            <strong>Tanggal Opening:</strong>{" "}
            {store.opening
              ? new Date(store.opening)
                  .toISOString()
                  .substr(0, 10)
                  .replace(/-/g, "/")
              : ""}
          </p>
          <p>
            <strong>Alamat:</strong> {store.alamat}
          </p>
          <p>
            <strong>Kota:</strong> {store.kota}
          </p>
          <p>
            <strong>Investasi:</strong>{" "}
            {numeral(store.investasi).format("0,0.")}
          </p>
          <p>
            <strong>Modal:</strong> {numeral(totalInvestasi).format("0,0.")}
          </p>
          <p>
            <strong>Note:</strong> {store.note}
          </p>
        </div>
      )}
      <div className="flex mt-8 space-x-4">
        <button
          onClick={shareViaWhatsApp}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 flex items-center">
          <FaWhatsapp className="mr-2" /> Share
        </button>
        <button
          onClick={shareViaTelegram}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center">
          <FaTelegram className="mr-2" /> Share
        </button>
      </div>
    </div>
  );
};

export default DetailStore;
