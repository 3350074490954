import React, { useState, useEffect } from "react";
import axios from "axios";
import EditMitraModal from "./EditMitraModal";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { API_URL } from "../../constants/apiConfig";

const DetailMitra = ({ mitra, onMitraUpdated = () => {} }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedMitra, setEditedMitra] = useState(mitra);
  const [showKtpModal, setShowKtpModal] = useState(false);
  const [imageHeight, setImageHeight] = useState(window.innerHeight * 0.75);

  useEffect(() => {
    setEditedMitra(mitra);
  }, [mitra]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedMitra((prevMitra) => ({
      ...prevMitra,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/mitra/${mitra.id}`,
        editedMitra
      );
      if (response.status === 200) {
        setIsEditing(false);
        onMitraUpdated();
      }
    } catch (error) {
      console.error("Gagal mengupdate mitra:", error);
    }
  };

  const showKtpPhotoModal = () => {
    setShowKtpModal(true);
  };

  const hideKtpPhotoModal = () => {
    setShowKtpModal(false);
  };

  const handleKTPChange = async (e) => {
    const newKTP = e.target.files[0];
    if (newKTP) {
      try {
        const formData = new FormData();
        formData.append("newKTP", newKTP);
        const response = await axios.put(
          `${API_URL}/mitra/update-ktp/${mitra.id}`,
          formData
        );
        if (response.status === 200) {
          // Refresh data kontrak setelah mengganti gambar KTP
          onMitraUpdated();
        }
      } catch (error) {
        console.error("Gagal mengganti gambar KTP:", error);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setImageHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shareViaWhatsApp = () => {
    const message = `Nama: ${mitra.name}\nId Mitra: ${mitra.id}\nNomor ID: ${mitra.personalid}\nAlamat: ${mitra.address}\nKota: ${mitra.kota}\nRekening Bank: ${mitra.account}\nBank: ${mitra.bank}\nRek a/n: ${mitra.accountname}\nKontak: ${mitra.kontak}\nEmail: ${mitra.email}\nInfo Lainnya: ${mitra.other}`;
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  const shareViaTelegram = () => {
    const message = `Nama: ${mitra.name}\nId Mitra: ${mitra.id}\nNomor ID: ${mitra.personalid}\nAlamat: ${mitra.address}\nKota: ${mitra.kota}\nRekening Bank: ${mitra.account}\nBank: ${mitra.bank}\nRek a/n: ${mitra.accountname}\nKontak: ${mitra.kontak}\nEmail: ${mitra.email}\nInfo Lainnya: ${mitra.other}`;
    const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(
      message
    )}`;
    window.open(telegramURL, "_blank");
  };

  return (
    <div className="overflow-auto max-h-[calc(100vh-100px)] p-4 rounded-md max-w-screen-md mb-4 shadow-md">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-bold text-xl md:text-4xl">
          Detail <br className="md:hidden" />{" "}
          <span className="md:inline md:mr-2">&nbsp;</span>
          Data Mitra
        </h2>
        <button
          onClick={handleEditClick}
          className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600">
          Edit Mitra
        </button>
      </div>

      {isEditing ? (
        <EditMitraModal
          editedMitra={editedMitra}
          handleInputChange={handleInputChange}
          handleSaveClick={handleSaveClick}
          handleCancelEdit={handleCancelEdit}
        />
      ) : (
        <div className="mt-4 md:grid md:grid-cols-2 text-sm">
          <p>
            <strong>Nama:</strong> {mitra.name}
          </p>
          <p>
            <strong>Id Mitra:</strong> {mitra.id}
          </p>
          <p>
            <strong>Nomor ID:</strong> {mitra.personalid}
          </p>
          <p>
            <strong>Alamat:</strong> {mitra.address}
          </p>
          <p>
            <strong>Kota:</strong> {mitra.kota}
          </p>
          <p>
            <strong>Rekening Bank:</strong> {mitra.account}
          </p>
          <p>
            <strong>Bank:</strong> {mitra.bank}
          </p>
          <p>
            <strong>Rek a/n:</strong> {mitra.accountname}
          </p>
          <p>
            <strong>Kontak:</strong> {mitra.kontak}
          </p>
          <p>
            <strong>Email:</strong> {mitra.email}
          </p>
          <p className=" col-span-2">
            <strong>Info Lainnya:</strong> {mitra.other}
          </p>
        </div>
      )}
      <div className="mt-4 ">
        <button
          onClick={showKtpPhotoModal}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 mb-4 md:mr-4">
          Tampilkan Foto KTP
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          onClick={() => document.getElementById("ktpInput").click()}>
          Ganti Foto KTP
        </button>
        <input
          type="file"
          accept="image/*"
          onChange={handleKTPChange}
          style={{ display: "none" }}
          id="ktpInput"
        />
      </div>

      <div className="flex mt-8 space-x-4">
        <button
          onClick={shareViaWhatsApp}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 flex items-center">
          <FaWhatsapp className="mr-2" /> Share
        </button>
        <button
          onClick={shareViaTelegram}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center">
          <FaTelegram className="mr-2" /> Share
        </button>
      </div>

      {showKtpModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-md max-w-md">
            <strong>Gambar KTP:</strong>
            <img
              src={`${API_URL}/uploads/${mitra.ktpImageUrl}`}
              alt="KTP Mitra"
              className="mt-2 max-w-full"
              style={{ maxHeight: `${imageHeight}px`, maxWidth: "100%" }} // Tambahkan maxWidth untuk memastikan gambar tidak melebar.
            />
            <button
              onClick={hideKtpPhotoModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
              Tutup
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailMitra;
