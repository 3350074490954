import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrash, FaEye } from "react-icons/fa";
import DeleteModal from "../../layouts/ui/DeleteModal";
import DetailStoreModal from "./DetailStoreModal";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import numeral from "numeral";
import { API_URL } from "../../constants/apiConfig";

const OwnStoreList = ({ selectedMitra, onOwnStoreUpdated }) => {
  const [ownStoreData, setOwnStoreData] = useState([]);
  const [editStoreId, setEditStoreId] = useState(null);
  const [editStoreData, setEditStoreData] = useState(null);
  const [selectedViewStore, setSelectedViewStore] = useState(null);
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedOwnStoreToDelete, setSelectedOwnStoreToDelete] =
    useState(null);
  const [selectedOwnStoresToShare, setSelectedOwnStoresToShare] = useState([]);

  useEffect(() => {
    fetchOwnStoreData(selectedMitra.id);
  }, [selectedMitra, onOwnStoreUpdated]);

  const fetchOwnStoreData = async (mitraId) => {
    try {
      const response = await axios.get(`${API_URL}/ownstore`);
      setOwnStoreData(response.data);
    } catch (error) {
      console.error("Failed to fetch own store data:", error);
    }
  };

  // Function to open detail modal
  const handleViewClick = (store) => {
    setSelectedViewStore(store);
    setIsDetailViewOpen(true);
  };

  // Function to close detail modal
  const handleCloseDetailView = () => {
    setSelectedViewStore(null);
    setIsDetailViewOpen(false);
  };

  const handleCancelEdit = () => {
    setEditStoreId(null);
    setEditStoreData(null);
  };

  // Open delete modal
  const handleDeleteClick = (store) => {
    setSelectedOwnStoreToDelete(store);
    setDeleteModalOpen(true);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/ownstore/${editStoreData.id}`,
        editStoreData
      );
      if (response.status === 200) {
        setEditStoreId(null);
        setEditStoreData(null);
        fetchOwnStoreData(selectedMitra.id);
      }
    } catch (error) {
      console.error("Gagal mengupdate kepemilikan toko:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditStoreData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Delete the selected mitra
  const handleDeleteConfirm = async () => {
    if (selectedOwnStoreToDelete) {
      try {
        const response = await axios.delete(
          `${API_URL}/ownstore/${selectedOwnStoreToDelete.id}`
        );
        if (response.status === 200) {
          setOwnStoreData(
            ownStoreData.filter(
              (store) => store.id !== selectedOwnStoreToDelete.id
            )
          );

          // Panggil fungsi pembaruan daftar
          onOwnStoreUpdated();
        }
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Gagal menghapus mitra:", error);
      }
    }
  };

  // Filter data based on selectedViewStore or default filter
  const filteredOwnStoreData = selectedViewStore
    ? [selectedViewStore]
    : ownStoreData.filter((store) => store.idmitra === selectedMitra.id);

  // Function to share data via WhatsApp
  const shareViaWhatsApp = () => {
    const selectedMitraData = ownStoreData.filter(
      (store) => store.idmitra === selectedMitra.id
    );

    const message = selectedMitraData
      .map((store) => {
        return `Store ID: ${store.id}\nStore Name: ${store.store}\nMitra: ${store.name}\nContract Number: ${store.kontrak}\nSlot: ${store.slot}\n\n`;
      })
      .join("\n");

    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  // Function to share data via Telegram
  const shareViaTelegram = () => {
    const selectedMitraData = ownStoreData.filter(
      (store) => store.idmitra === selectedMitra.id
    );

    const message = selectedMitraData
      .map((store) => {
        return `Store ID: ${store.id}\nStore Name: ${store.store}\nMitra: ${store.name}\nContract Number: ${store.kontrak}\nSlot: ${store.slot}\n\n`;
      })
      .join("\n");

    const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(
      message
    )}`;
    window.open(telegramURL, "_blank");
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-md overflow-auto max-h-[calc(100vh-100px)]">
      <h2 className="text-xl font-semibold mb-4">Data Kepemilikan Toko</h2>
      <table className="w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 text-center text-xs md:text-sm hidden md:table-cell">
              ID
            </th>
            <th className="px-4 py-2 text-center text-xs md:text-sm">Store</th>
            <th className="px-4 py-2 text-center text-xs md:text-sm hidden md:table-cell">
              Nomor Kontrak
            </th>
            <th className="px-4 py-2 text-center text-xs md:text-sm">Slot</th>
            <th className="px-4 py-2 text-center text-xs md:text-sm">
              Investasi
            </th>
            <th className="px-4 py-2 text-center text-xs md:text-sm"></th>
          </tr>
        </thead>
        <tbody>
          {filteredOwnStoreData.map((store) => (
            <tr key={store.id}>
              <td className="hidden md:block">{store.id}</td>
              <td>{store.store}</td>
              <td className="hidden md:block">
                {editStoreId === store.id ? (
                  <input
                    type="text"
                    name="kontrak"
                    value={editStoreData.kontrak}
                    onChange={handleInputChange}
                  />
                ) : (
                  store.kontrak
                )}
              </td>
              <td className="text-center">
                {editStoreId === store.id ? (
                  <input
                    type="text"
                    name="slot"
                    value={editStoreData.slot}
                    onChange={handleInputChange}
                  />
                ) : (
                  store.slot
                )}
              </td>
              <td className="text-center">
                {editStoreId === store.id ? (
                  <input
                    type="text"
                    name="investasi"
                    value={editStoreData.investasi}
                    onChange={handleInputChange}
                  />
                ) : (
                  numeral(store.investasi).format("0,0.")
                )}
              </td>
              <td>
                {editStoreId === store.id ? (
                  <>
                    <button onClick={handleSaveEdit}>Save</button>
                    <button onClick={handleCancelEdit}>Cancel</button>
                  </>
                ) : (
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleViewClick(store)}
                      className="text-blue-500 hover:text-green-800">
                      <FaEye />
                    </button>

                    <button
                      onClick={() => handleDeleteClick(store)} // Open delete modal
                      className="text-red-600 hover:text-red-800">
                      <FaTrash />
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex mt-8 space-x-4">
        <button
          onClick={shareViaWhatsApp}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 flex items-center">
          <FaWhatsapp className="mr-2" /> Share
        </button>
        <button
          onClick={shareViaTelegram}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center">
          <FaTelegram className="mr-2" /> Share
        </button>
      </div>

      {selectedViewStore && isDetailViewOpen && (
        <DetailStoreModal
          ownstore={selectedViewStore}
          onClose={handleCloseDetailView}
          onOwnStoreUpdated={fetchOwnStoreData} // Panggil ulang data setelah update
        />
      )}
      {/* Add DeleteModal component */}
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleDeleteConfirm}
      />
    </div>
  );
};

export default OwnStoreList;
