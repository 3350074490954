import React, { useEffect } from "react";

const EditMitraModal = ({
  editedMitra,
  handleInputChange,
  handleSaveClick,
  handleCancelEdit,
}) => {
  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (event.target.classList.contains("overlay")) {
        handleCancelEdit();
      }
    };

    document.addEventListener("click", handleOverlayClick);

    return () => {
      document.removeEventListener("click", handleOverlayClick);
    };
  }, [handleCancelEdit]);
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 overlay">
      <div className="bg-white p-6 rounded-md shadow-md grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <h2 className="font-bold text-xl mb-4">Edit Mitra</h2>
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Nama:</label>
          <input
            type="text"
            name="name"
            value={editedMitra.name}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Nomor KTP:</label>
          <input
            type="text"
            name="personalid"
            value={editedMitra.personalid}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Alamat:</label>
          <input
            type="text"
            name="address"
            value={editedMitra.address}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Akun Bank:</label>
          <input
            type="text"
            name="account"
            value={editedMitra.account}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Bank:</label>
          <input
            type="text"
            name="bank"
            value={editedMitra.bank}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Atas Nama:</label>
          <input
            type="text"
            name="accountname"
            value={editedMitra.accountname}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Kontak:</label>
          <input
            type="text"
            name="kontak"
            value={editedMitra.kontak}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Email:</label>
          <input
            type="text"
            name="email"
            value={editedMitra.email}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-2 mb-4">
          <label className="block font-semibold">Info Lainnya:</label>
          <input
            type="text"
            name="other"
            value={editedMitra.other}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-2 justify-between flex">
          <button
            onClick={handleSaveClick}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
            Simpan
          </button>
          <button
            onClick={handleCancelEdit}
            className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-600">
            Batal
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditMitraModal;
