import React, { useState, useEffect } from "react";
import axios from "axios";
import TambahStoreForm from "./TambahStoreForm";
import DetailStore from "./DetailStore";
import OwnStoreList from "./OwnStoreList";
import DeleteModal from "../../layouts/ui/DeleteModal";
import { FaPlus } from "react-icons/fa";
import { API_URL } from "../../constants/apiConfig";

const StoreList = () => {
  const [storeData, setStoreData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedStoreToDelete, setSelectedStoreToDelete] = useState(null);
  const [showTambahForm, setShowTambahForm] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [showDetail, setShowDetail] = useState(false); // State untuk mengontrol tampilan detail

  // Fetch data from the server on component mount
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/store`);
      setStoreData(response.data);
    } catch (error) {
      console.error("Gagal mengambil data Store:", error);
    }
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const handleSortClick = () => {
    const sortedData = [...storeData].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      return sortAscending
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });

    setStoreData(sortedData);
    setSortAscending(!sortAscending);
  };

  const handleStoreAdded = async () => {
    setShowTambahForm(false);
    await fetchData(); // Refresh data
  };

  const handleDetailClick = (store) => {
    setSelectedStore(store);
  };

  const handleDeleteClick = (store) => {
    setSelectedStoreToDelete(store);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedStoreToDelete) {
      try {
        await axios.delete(`${API_URL}/store/${selectedStoreToDelete.id}`);

        // Remove the deleted store from the storeData
        setStoreData((prevData) =>
          prevData.filter((store) => store.id !== selectedStoreToDelete.id)
        );

        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Gagal menghapus store:", error);
      }
    }
  };

  const handleStoreUpdated = () => {
    fetchData();
  };

  return (
    <div className="max-h-screen">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-bold text-xl md:text-4xl">
          DATABASE
          <br className="md:hidden" />
          <span className="md:inline md:mr-2">&nbsp;</span>
          STORE CRS
        </h2>
        <button
          onClick={() => setShowTambahForm(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-blue-600">
          <FaPlus />
        </button>
      </div>
      <div className="bg-slate-700 rounded-xl p-4 my-4">
        {/* Tambahkan class "flex-col" untuk mengatur tata letak menjadi kolom pada layar kecil */}
        <div className="flex flex-col sm:flex-row justify-between items-center">
          {/* Tambahkan class "mb-2" agar label pindah ke bawah pada layar kecil */}
          <h3 className="text-xl text-white mb-2 sm:mb-0">Search :</h3>
          <input
            type="text"
            value={filterText}
            onChange={handleFilterChange}
            className="px-2 py-1 border rounded-md focus:outline-none w-full md:w-64 text-center"
            placeholder="Cari Berdasarkan Nama Toko"
          />
        </div>
      </div>
      {/* Menampilkan tabel hanya jika tidak ada data yang dipilih */}
      {!selectedStore && (
        <div className="bg-gray-400 rounded-md overflow-auto max-h-[calc(100vh-200px)] custom-scrollbar">
          <table className="mt-4 w-full">
            <thead className="sticky top-0 bg-gray-400">
              <tr className="text-center text-sm md:text-base">
                <th
                  onClick={handleSortClick}
                  className="cursor-pointer text-left">
                  Nama <span className="ml-1">{sortAscending ? "▼" : "▲"}</span>
                </th>
                <th className="text-left">Kota</th>
                <th>Investasi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {storeData
                .filter((store) =>
                  store.name.toLowerCase().includes(filterText.toLowerCase())
                )
                .map((store) => (
                  <tr
                    key={store.id}
                    onClick={() => handleDetailClick(store)}
                    className="border-b bg-gray-800 border-gray-700 hover:bg-gray-600 text-gray-100">
                    <td className="p-4 text-xs md:text-base">{store.name}</td>
                    <td className="text-xs md:text-base">{store.kota}</td>
                    <td className="text-xs md:text-base">{store.investasi}</td>
                    <td>
                      <button
                        onClick={() => handleDeleteClick(store)}
                        className="text-red-600 hover:text-red-800">
                        <svg
                          xmlns="https://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Menampilkan Detail dan OwnStoreList */}
      {selectedStore && !showTambahForm && !selectedStore.isEditing && (
        <div className="mt-4">
          <button
            onClick={() => setSelectedStore(null)}
            className="bg-blue-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-blue-600">
            Back to Store List
          </button>
          <DetailStore
            store={selectedStore}
            onStoreUpdated={handleStoreUpdated}
          />
          <OwnStoreList selectedStore={selectedStore} />
        </div>
      )}
      {/* Show TambahStoreForm as a pop-up if showTambahForm is true */}
      {showTambahForm && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <button
                onClick={() => setShowTambahForm(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                Close
              </button>
              <TambahStoreForm
                onStoreAdded={handleStoreAdded}
                setShowTambahForm={setShowTambahForm}
              />
            </div>
          </div>
        </div>
      )}

      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleDeleteConfirm}
      />
    </div>
  );
};

export default StoreList;
