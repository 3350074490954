import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/layouts/sidebar";
import MitraList from "./components/modules/mitra/mitra_list";
import StoreList from "./components/modules/store/store_list";
import Login from "./components/layouts/Login.jsx";
import Logout from "./components/layouts/Logout";
import KontrakList from "./components/modules/kontrak/list_kontrak";
import InputTransfer from "./components/modules/input/inputtransfer";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Cek local storage saat komponen dimuat
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    // Simpan status login di local storage
    localStorage.setItem("loggedIn", "true");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    // Hapus status login dari local storage saat logout
    localStorage.removeItem("loggedIn");
  };

  const ProtectedRoute = ({ element, ...props }) => {
    return isLoggedIn ? element : <Navigate to="/sign-in" />;
  };

  return (
    <Router>
      <div className="flex">
        {isLoggedIn && <Sidebar onLogout={handleLogout} />}
        <main className={`flex-grow ${isLoggedIn ? "p-4" : ""}`}>
          <Routes>
            <Route path="/sign-in" element={<Login onLogin={handleLogin} />} />
            <Route
              path="/"
              element={<ProtectedRoute element={<MitraList />} />}
            />
            <Route
              path="/input-transfer"
              element={<ProtectedRoute element={<InputTransfer />} />}
            />
            <Route
              path="/list-mitra"
              element={<ProtectedRoute element={<MitraList />} />}
            />
            <Route
              path="/list-store"
              element={<ProtectedRoute element={<StoreList />} />}
            />
            <Route
              path="/list-kontrak"
              element={<ProtectedRoute element={<KontrakList />} />}
            />
            <Route
              path="/sign-out"
              element={
                <ProtectedRoute element={<Logout onLogout={handleLogout} />} />
              }
            />
            {/* Tambahkan rute berikut untuk menangkap path yang tidak dikenal */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
