import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrash, FaEye } from "react-icons/fa";
import DeleteModal from "../../layouts/ui/DeleteModal";
import DetailStoreModal from "./DetailStoreModal";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import numeral from "numeral";
import * as XLSX from "xlsx";
import { API_URL } from "../../constants/apiConfig";

const OwnStoreList = ({ selectedStore }) => {
  const [ownStoreData, setOwnStoreData] = useState([]);
  const [editStoreId, setEditStoreId] = useState(null);
  const [editStoreData, setEditStoreData] = useState(null);
  const [selectedViewStore, setSelectedViewStore] = useState(null);
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedOwnStoreToDelete, setSelectedOwnStoreToDelete] =
    useState(null);

  useEffect(() => {
    fetchOwnStoreData(selectedStore.id);
  }, [selectedStore]);

  const fetchOwnStoreData = async (storeId) => {
    try {
      const ownStoreResponse = await axios.get(`${API_URL}/ownstore`);
      const mitraResponse = await axios.get(`${API_URL}/mitra`);

      // Menggabungkan data ownStoreData dengan data mitraData berdasarkan idmitra
      const updatedOwnStoreData = ownStoreResponse.data.map((store) => {
        const matchingMitra = mitraResponse.data.find(
          (mitra) => mitra.id === store.idmitra
        );

        if (matchingMitra) {
          // Menambahkan informasi email, rekening, dan bank dari mitra ke data ownstore
          return {
            ...store,
            email: matchingMitra.email,
            account: matchingMitra.account,
            bank: matchingMitra.bank,
          };
        }

        return store;
      });

      setOwnStoreData(updatedOwnStoreData);
    } catch (error) {
      console.error("Failed to fetch own store data:", error);
    }
  };

  // Function to open detail modal
  const handleViewClick = (store) => {
    setSelectedViewStore(store);
    setIsDetailViewOpen(true);
  };

  // Function to close detail modal
  const handleCloseDetailView = () => {
    setSelectedViewStore(null);
    setIsDetailViewOpen(false);
  };

  const handleCancelEdit = () => {
    setEditStoreId(null);
    setEditStoreData(null);
  };

  // Open delete modal
  const handleDeleteClick = (store) => {
    setSelectedOwnStoreToDelete(store);
    setDeleteModalOpen(true);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/ownstore/${editStoreData.id}`,
        editStoreData
      );
      if (response.status === 200) {
        setEditStoreId(null);
        setEditStoreData(null);
        fetchOwnStoreData(selectedStore.id);
      }
    } catch (error) {
      console.error("Gagal mengupdate kepemilikan toko:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditStoreData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Delete the selected store
  const handleDeleteConfirm = async () => {
    if (selectedOwnStoreToDelete) {
      try {
        const response = await axios.delete(
          `${API_URL}/ownstore/${selectedOwnStoreToDelete.id}`
        );
        if (response.status === 200) {
          setOwnStoreData(
            ownStoreData.filter(
              (store) => store.id !== selectedOwnStoreToDelete.id
            )
          );
        }
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Gagal menghapus store:", error);
      }
    }
  };

  // Filter data based on selectedViewStore or default filter
  const filteredOwnStoreData = selectedViewStore
    ? [selectedViewStore]
    : ownStoreData.filter((store) => store.idstore === selectedStore.id);

  // Function to share data via WhatsApp
  const shareViaWhatsApp = () => {
    const selectedStoreData = ownStoreData.filter(
      (store) => store.idstore === selectedStore.id
    );

    const message = selectedStoreData
      .map((store) => {
        return `Store ID: ${store.id}\nStore Name: ${store.store}\nMitra: ${store.name}\nContract Number: ${store.kontrak}\nSlot: ${store.slot}\n\n`;
      })
      .join("\n");

    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  // Function to share data via Telegram
  const shareViaTelegram = () => {
    const selectedStoreData = ownStoreData.filter(
      (store) => store.idstore === selectedStore.id
    );

    const message = selectedStoreData
      .map((store) => {
        return `Store ID: ${store.id}\nStore Name: ${store.store}\nMitra: ${store.name}\nContract Number: ${store.kontrak}\nSlot: ${store.slot}\n\n`;
      })
      .join("\n");

    const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(
      message
    )}`;
    window.open(telegramURL, "_blank");
  };

  const exportToExcel = () => {
    const dataToExport = filteredOwnStoreData.map((store) => ({
      Mitra: store.name,
      Investasi: store.investasi,
      Email: store.email,
      Rekening: store.account,
      Bank: store.bank,
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data Kepemilikan Toko");

    // Simpan file Excel
    XLSX.writeFile(wb, "kepemilikan_toko.xlsx");
  };

  const handleSortClick = () => {
    const sortedData = [...ownStoreData].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      return sortAscending
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });

    setOwnStoreData(sortedData);
    setSortAscending(!sortAscending);
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-lg overflow-auto max-h-[calc(100vh-100px)]">
      <h2 className="text-xl font-semibold mb-4">Data Kepemilikan Toko</h2>
      <div className="bg-gray-400 rounded-md overflow-auto max-h-[calc(100vh-200px)] custom-scrollbar">
        <table className="mt-4 w-full">
          <thead className="sticky top-0 bg-gray-400">
            <tr className=" text-xs md:text-sm">
              <th
                onClick={handleSortClick}
                className="cursor-pointer px-4 py-2 text-left">
                Mitra <span className="ml-1">{sortAscending ? "▼" : "▲"}</span>
              </th>
              <th className="px-4 py-2 text-center">Investasi</th>
              <th className="px-4 py-2 text-left hidden md:table-cell">
                Email
              </th>
              <th className="px-4 py-2 text-left hidden md:table-cell">
                Rekening
              </th>
              <th className="px-4 py-2 text-left hidden md:table-cell">Bank</th>
              <th className="px-4 py-2 text-center "></th>
            </tr>
          </thead>
          <tbody>
            {filteredOwnStoreData.map((store) => (
              <tr
                key={store.id}
                className="  border-b bg-gray-800 border-gray-700 hover:bg-gray-600 text-gray-100">
                <td className="px-4 py-2">{store.name}</td>
                <td className="text-center px-4 py-2">
                  {editStoreId === store.id ? (
                    <input
                      type="text"
                      name="investasi"
                      value={editStoreData.investasi}
                      onChange={handleInputChange}
                    />
                  ) : (
                    numeral(store.investasi).format("0,0.")
                  )}
                </td>
                <td className="hidden md:table-cell">{store.email}</td>
                <td className="hidden md:table-cell">{store.account}</td>
                <td className="hidden md:table-cell">{store.bank}</td>

                <td>
                  {editStoreId === store.id ? (
                    <>
                      <button onClick={handleSaveEdit}>Save</button>
                      <button onClick={handleCancelEdit}>Cancel</button>
                    </>
                  ) : (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleViewClick(store)}
                        className="text-blue-500 hover:text-green-800">
                        <FaEye />
                      </button>

                      <button
                        onClick={() => handleDeleteClick(store)} // Open delete modal
                        className="text-red-600 hover:text-red-800">
                        <FaTrash />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex mt-8 space-x-4">
        <button
          onClick={shareViaWhatsApp}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 flex items-center">
          <FaWhatsapp className="mr-2" /> Share
        </button>
        <button
          onClick={shareViaTelegram}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center">
          <FaTelegram className="mr-2" /> Share
        </button>
        <button
          onClick={exportToExcel}
          className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center">
          Excel
        </button>
      </div>

      {selectedViewStore && isDetailViewOpen && (
        <DetailStoreModal
          ownstore={selectedViewStore}
          onClose={handleCloseDetailView}
          onOwnStoreUpdated={fetchOwnStoreData} // Panggil ulang data setelah update
        />
      )}
      {/* Add DeleteModal component */}
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleDeleteConfirm}
      />
    </div>
  );
};

export default OwnStoreList;
