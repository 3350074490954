import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../constants/apiConfig";

const EditKontrakModal = ({
  editedKontrak,
  handleInputChange,
  handleCancelEdit,
  handleTrfInImageChange, // Anda perlu memasukkan ini dari komponen parent
  handleTrfOutImageChange, // Anda perlu memasukkan ini dari komponen parent
}) => {
  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (event.target.classList.contains("overlay")) {
        handleCancelEdit();
      }
    };

    document.addEventListener("click", handleOverlayClick);

    return () => {
      document.removeEventListener("click", handleOverlayClick);
    };
  }, [handleCancelEdit]);

  const [showTrfModal, setShowTrfModal] = useState(false);
  const [showTrfOutModal, setShowTrfOutModal] = useState(false);
  const [newTrfIn, setNewTrfIn] = useState(null);
  const [newTrfOut, setNewTrfOut] = useState(null);

  const showTrfPhotoModal = () => {
    setShowTrfModal(true);
  };

  const hideTrfPhotoModal = () => {
    setShowTrfModal(false);
  };

  const showTrfOutPhotoModal = () => {
    setShowTrfOutModal(true);
  };

  const hideTrfOutPhotoModal = () => {
    setShowTrfOutModal(false);
  };

  const handleTrfInChange = (e) => {
    setNewTrfIn(e.target.files[0]);
  };

  const handleTrfOutChange = (e) => {
    setNewTrfOut(e.target.files[0]);
  };

  const handleSaveClick = async () => {
    try {
      // Update data kontrak dengan perubahan yang telah dibuat
      const response = await axios.put(
        `${API_URL}/ownstore/${editedKontrak.id}`,
        editedKontrak
      );

      if (response.status === 200) {
        // Jika data kontrak berhasil diperbarui, periksa apakah ada gambar TrfIn yang baru
        if (newTrfIn) {
          const formData = new FormData();
          formData.append("newTrfIn", newTrfIn);

          // Upload gambar TrfIn baru
          const uploadResponse = await axios.put(
            `${API_URL}/ownstore/update-trfIn/${editedKontrak.id}`,
            formData
          );

          if (uploadResponse.status === 200) {
            // Gambar TrfIn berhasil diunggah
            // Refresh data kontrak untuk memastikan gambar terbaru ditampilkan
            handleTrfInImageChange(); // Panggil fungsi handleTrfInImageChange yang diberikan dari komponen parent
          }
        }

        // Lakukan hal yang sama untuk gambar TrfOut jika diperlukan
        if (newTrfOut) {
          const formData = new FormData();
          formData.append("newTrfOut", newTrfOut);

          // Upload gambar TrfIn baru
          const uploadResponse = await axios.put(
            `${API_URL}/ownstore/update-trfOut/${editedKontrak.id}`,
            formData
          );

          if (uploadResponse.status === 200) {
            // Gambar TrfOut berhasil diunggah
            // Refresh data kontrak untuk memastikan gambar terbaru ditampilkan
            handleTrfOutImageChange(); // Panggil fungsi handleTrfOutImageChange yang diberikan dari komponen parent
          }
        }
        // Tutup modal setelah berhasil menyimpan dan mengunggah gambar
        handleCancelEdit();
      } else {
        console.error("Gagal mengupdate kontrak:", response);
      }
    } catch (error) {
      console.error("Gagal mengupdate kontrak:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 overlay">
      <div className="bg-white p-6 rounded-md shadow-md grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <h2 className="font-bold text-xl mb-4">Edit Kontrak</h2>
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Nomor Kontrak:</label>
          <input
            type="text"
            name="kontrak"
            value={editedKontrak.kontrak}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Store:</label>
          <input
            type="text"
            name="store"
            value={editedKontrak.store}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Mitra:</label>
          <input
            type="text"
            name="name"
            value={editedKontrak.name}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Tanggal Bergabung:</label>
          <input
            type="date"
            name="joindate"
            value={
              editedKontrak.joindate
                ? new Date(editedKontrak.joindate).toISOString().substr(0, 10)
                : ""
            }
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Tanggal Berhenti:</label>
          <input
            type="date"
            name="outdate"
            value={
              editedKontrak.outdate
                ? new Date(editedKontrak.outdate).toISOString().substr(0, 10)
                : ""
            }
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Alasan Berhenti:</label>
          <input
            type="text"
            name="reason"
            value={editedKontrak.reason}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Slot:</label>
          <input
            type="number"
            name="slot"
            value={editedKontrak.slot}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>

        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Information:</label>
          <input
            type="text"
            name="info"
            value={editedKontrak.information}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>

        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Investasi:</label>
          <input
            type="number"
            name="investasi"
            value={editedKontrak.investasi}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>

        <div className="mt-4">
          <button
            onClick={showTrfPhotoModal}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
            Tampilkan Foto Transfer Modal
          </button>
        </div>
        <div className="mt-4">
          <button
            onClick={showTrfOutPhotoModal}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
            Tampilkan Foto Pencairan Modal
          </button>
        </div>
        {showTrfModal && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-4 rounded-md max-w-md">
              <strong>Gambar Bukti Pembayaran:</strong>
              <img
                src={`${API_URL}/uploads/${editedKontrak.trfInUrl}`}
                alt="Foto Kontrak"
                className="mt-2 max-w-full"
              />
              <button
                onClick={hideTrfPhotoModal}
                className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
                Tutup
              </button>
            </div>
          </div>
        )}
        {showTrfOutModal && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-4 rounded-md max-w-md">
              <strong>Gambar Bukti Pencairan:</strong>
              <img
                src={`${API_URL}/uploads/${editedKontrak.trfOutUrl}`}
                alt="Foto Pencairan"
                className="mt-2 max-w-full"
              />
              <button
                onClick={hideTrfOutPhotoModal}
                className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
                Tutup
              </button>
            </div>
          </div>
        )}
        <div className="col-span-2 justify-between flex">
          <button
            onClick={handleSaveClick}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
            Simpan
          </button>
          <button
            onClick={handleCancelEdit}
            className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-600">
            Batal
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditKontrakModal;
