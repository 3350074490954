import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/apiConfig";

const StoreProcess = ({ setShowTambahForm, onKontrakAdded }) => {
  const [storeOptions, setStoreOptions] = useState([]);
  const [mitraOptions, setMitraOptions] = useState([]);
  const [formData, setFormData] = useState({
    idstore: "",
    idmitra: "",
    kontrak: "",
    joindate: "",
    outdate: "",
    reason: "",
    slot: "",
    information: "",
  });

  useEffect(() => {
    fetchStoreOptions();
    fetchMitraOptions();
  }, []);

  const fetchStoreOptions = async () => {
    try {
      const response = await axios.get("${API_URL}/store");
      setStoreOptions(response.data);
    } catch (error) {
      console.error("Failed to fetch store options:", error);
    }
  };

  const fetchMitraOptions = async () => {
    try {
      const response = await axios.get(`${API_URL}/mitra`);
      setMitraOptions(response.data);
    } catch (error) {
      console.error("Failed to fetch mitra options:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("${API_URL}/ownstore", formData);
      if (response.status === 201) {
        // Clear the form after successful submission
        setFormData({
          idstore: "",
          idmitra: "",
          kontrak: "",
          joindate: "",
          outdate: "",
          reason: "",
          slot: "",
          information: "",
        });
        console.log("Data successfully submitted to tb_ownstore");

        // Call the callback function to update KontrakList
        onKontrakAdded();
        navigate("/list-kontrak");
      }
    } catch (error) {
      console.error("Failed to submit data to tb_ownstore:", error);
    }
  };

  const handleCancelClick = () => {
    setShowTambahForm(false);
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-md">
      <h2 className="text-xl font-semibold mb-4">
        Proses Pengisian Tabel tb_ownstore
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="grid md:grid-cols-2 md:gap-4">
          <div className="mb-4">
            <label className="block text-sm font-semibold">Nama Store:</label>
            <select
              name="idstore"
              value={formData.idstore}
              onChange={handleInputChange}
              className="border p-2 rounded w-full">
              <option value="">Pilih Nama Store</option>
              {storeOptions.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">Id Store:</label>
            <input
              type="text"
              name="idstore"
              value={formData.idstore}
              onChange={handleInputChange}
              disabled
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">Nama Mitra:</label>
            <select
              name="idmitra"
              value={formData.idmitra}
              onChange={handleInputChange}
              className="border p-2 rounded w-full">
              <option value="">Pilih Nama Mitra</option>
              {mitraOptions.map((mitra) => (
                <option key={mitra.id} value={mitra.id}>
                  {mitra.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">Id Mitra:</label>
            <input
              type="text"
              name="idmitra"
              value={formData.idmitra}
              onChange={handleInputChange}
              disabled
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">Kontrak:</label>
            <input
              type="text"
              name="kontrak"
              value={formData.kontrak}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">
              Tanggal Bergabung:
            </label>
            <input
              type="date"
              name="joindate"
              value={formData.joindate}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">
              Tanggal Berhenti:
            </label>
            <input
              type="date"
              name="outdate"
              value={formData.outdate}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">
              Alasan Berhenti:
            </label>
            <input
              type="text"
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">Jumlah Slot:</label>
            <input
              type="number"
              name="slot"
              value={formData.slot}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold">Informasi:</label>
            <textarea
              name="information"
              value={formData.information}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Submit
        </button>
        <button
          type="button"
          onClick={handleCancelClick}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 ml-2">
          Batal
        </button>
      </form>
    </div>
  );
};

export default StoreProcess;
