import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/apiConfig";
import noImage from "../../../assets/noImage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InputTransfer = () => {
  const [mitraOptions, setMitraOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [formData, setFormData] = useState({
    idstore: "",
    idmitra: "",
    kontrak: "NA",
    joindate: "2023-01-01", // Nilai default untuk joindate
    outdate: "2099-01-01", // Nilai default untuk outdate
    reason: "NA", // Nilai default untuk reason
    slot: "0", // Nilai default untuk slot
    information: "NA", // Nilai default untuk information
    trfInUrl: null,
    investasi: "",
  });
  const [trfInUrlPreview, settrfInUrlPreview] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMitraOptions();
    fetchStoreOptions();
  }, []);

  const fetchMitraOptions = async () => {
    try {
      const response = await axios.get(`${API_URL}/mitra`);
      setMitraOptions(response.data);
    } catch (error) {
      console.error("Failed to fetch mitra options:", error);
    }
  };

  const fetchStoreOptions = async () => {
    try {
      const response = await axios.get(`${API_URL}/store`);
      setStoreOptions(response.data);
    } catch (error) {
      console.error("Failed to fetch store options:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("idmitra", formData.idmitra);
      formDataToSend.append("idstore", formData.idstore);
      formDataToSend.append("kontrak", formData.kontrak);
      formDataToSend.append("joindate", formData.joindate);
      formDataToSend.append("outdate", formData.outdate);
      formDataToSend.append("reason", formData.reason);
      formDataToSend.append("slot", formData.slot);
      formDataToSend.append("investasi", formData.investasi);
      formDataToSend.append("information", formData.information);

      // Cek apakah formData.trfInUrl tidak null
      if (formData.trfInUrl) {
        formDataToSend.append("trfInUrl", formData.trfInUrl);
      } else {
        const noImageBlob = await fetch(noImage).then((res) => res.blob());
        formDataToSend.append("trfInUrl", noImageBlob, "noImage.png");
      }

      const response = await axios.post(`${API_URL}/ownstore`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        // Clear the form after successful submission
        setFormData({
          idmitra: "",
          idstore: "",
          kontrak: "NA",
          joindate: "2023-01-01", // Nilai default untuk joindate
          outdate: "2099-01-01", // Nilai default untuk outdate
          reason: "NA", // Nilai default untuk reason
          slot: "0", // Nilai default untuk slot
          information: "NA", // Nilai default untuk information
          investasi: "", // Nilai default untuk information
          trfInUrl: null,
        });
        console.log("Data successfully submitted to transfer table");

        // Call the callback function to update the transfer list
        settrfInUrlPreview(null);
        navigate("/input-transfer");
        // Set pesan sukses
        toast.success("Data berhasil disubmit dan file berhasil diupload!");
      }
    } catch (error) {
      console.error("Failed to submit data to transfer table:", error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      trfInUrl: file,
    }));

    const reader = new FileReader();
    reader.onload = () => {
      settrfInUrlPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="bg-gray-300 rounded-lg p-6 shadow-md max-w-md mx-auto max-h-[calc(100vh-200px)]">
      <h2 className="text-xl font-semibold mb-4">Input Transfer</h2>
      <ToastContainer />
      <form onSubmit={handleSubmit} className="md:grid md:grid-cols-2 md:gap-4">
        <div className="mb-4">
          <label className="block text-sm font-semibold">Nama Store:</label>
          <select
            required
            name="idstore"
            value={formData.idstore}
            onChange={handleInputChange}
            className="border p-2 rounded w-full">
            <option value="">Pilih Nama Store</option>
            {storeOptions.map((store) => (
              <option key={store.id} value={store.id}>
                {store.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold">Id Store:</label>
          <input
            type="text"
            name="idstore"
            value={formData.idstore}
            onChange={handleInputChange}
            disabled
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold">Nama Mitra:</label>
          <select
            required
            name="idmitra"
            value={formData.idmitra}
            onChange={handleInputChange}
            className="border p-2 rounded w-full">
            <option value="">Pilih Nama Mitra</option>
            {mitraOptions.map((mitra) => (
              <option key={mitra.id} value={mitra.id}>
                {mitra.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold">Id Mitra:</label>
          <input
            type="text"
            name="idmitra"
            value={formData.idmitra}
            onChange={handleInputChange}
            disabled
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold">
            Nominal Transfer:
          </label>
          <input
            type="number"
            name="investasi"
            value={formData.investasi}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
            required
          />
        </div>
        <div className="mb-4 col-span-2">
          <label htmlFor="trfInUrl" className="block font-medium text-gray-700">
            Upload
          </label>
          <input
            type="file"
            id="trfInUrl"
            name="trfInUrl"
            onChange={handleImageUpload}
            className="mt-1"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Submit
        </button>
      </form>
      <div className="mb-4">
        <label className="block font-medium text-gray-700">Pratinjau KTP</label>
        <img
          src={trfInUrlPreview || noImage}
          alt="KTP Preview"
          className="mt-2 max-w-xs"
        />
      </div>
    </div>
  );
};

export default InputTransfer;
