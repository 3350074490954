import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/apiConfig";

const TambahStoreForm = ({ onStoreAdded, setShowTambahForm }) => {
  const [formData, setFormData] = useState({
    name: "",
    opening: "",
    alamat: "",
    kota: "",
    investasi: "",
    note: "",
  });

  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/store`, formData);
      if (response.status === 201) {
        onStoreAdded(response.data);
        setFormData({
          name: "",
          opening: "",
          alamat: "",
          kota: "",
          investasi: "",
          note: "",
        });
        navigate("/list-store"); // Navigate to list-store page
        setShowTambahForm(false); // Close the form after successful submission
      }
    } catch (error) {
      console.error("Gagal menambahkan store:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancelClick = () => {
    setShowTambahForm(false);
  };
  return (
    <div className="bg-white rounded-lg p-6 shadow-md overflow-auto max-h-[calc(100vh-100px)]">
      <h2 className="text-xl font-semibold mb-4">Tambah STORE Baru</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Nama store
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="opening" className="block font-medium text-gray-700">
            Opening
          </label>
          <input
            type="date"
            id="opening"
            name="opening"
            value={formData.opening}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="alamat" className="block font-medium text-gray-700">
            Alamat
          </label>
          <input
            type="text"
            id="alamat"
            name="alamat"
            value={formData.alamat}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="kota" className="block font-medium text-gray-700">
            Kota
          </label>
          <input
            type="text"
            id="kota"
            name="kota"
            value={formData.kota}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="investasi"
            className="block font-medium text-gray-700">
            Investasi
          </label>
          <input
            type="number"
            id="investasi"
            name="investasi"
            value={formData.investasi}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="note" className="block font-medium text-gray-700">
            Informasi:
          </label>
          <textarea
            id="note"
            name="note"
            value={formData.note}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            rows="4"
          />
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Tambahkan Store
        </button>

        <button
          type="button"
          onClick={handleCancelClick}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 ml-2">
          Batal
        </button>
      </form>
    </div>
  );
};

export default TambahStoreForm;
