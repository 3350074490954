import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/apiConfig";

const TambahMitraForm = ({ onMitraAdded, setShowTambahForm }) => {
  const [formData, setFormData] = useState({
    name: "",
    personalid: "",
    address: "",
    kota: "",
    account: "",
    bank: "",
    other: "",
    ktpImage: null,
  });

  const [ktpImagePreview, setKtpImagePreview] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("personalid", formData.personalid);
      formDataToSend.append("address", formData.address);
      formDataToSend.append("kota", formData.kota);
      formDataToSend.append("account", formData.account);
      formDataToSend.append("bank", formData.bank);
      formDataToSend.append("accountname", formData.accountname);
      formDataToSend.append("kontak", formData.kontak);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("other", formData.other);
      if (formData.ktpImage) {
        formDataToSend.append("ktpImage", formData.ktpImage);
      }

      const response = await axios.post(`${API_URL}/mitra`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        onMitraAdded({
          ...response.data,
          ktpImage: response.data.ktpImageUrl, // Ubah ktpImage menjadi ktpImageUrl
        });
        setFormData({
          name: "",
          personalid: "",
          address: "",
          kota: "",
          account: "",
          bank: "",
          other: "",
          ktpImage: null,
        });
        setKtpImagePreview(null);
        navigate("/list-mitra");
      }
    } catch (error) {
      console.error("Gagal menambahkan mitra:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancelClick = () => {
    setShowTambahForm(false);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      ktpImage: file,
    }));

    const reader = new FileReader();
    reader.onload = () => {
      setKtpImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-md overflow-auto max-h-[calc(100vh-100px)]">
      <h2 className="text-xl font-semibold mb-4">Tambah Mitra Baru</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block font-medium text-gray-700">
            Nama Mitra
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="personalid"
            className="block font-medium text-gray-700">
            Nomor KTP / Identitas
          </label>
          <input
            type="text"
            id="personalid"
            name="personalid"
            value={formData.personalid}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="address" className="block font-medium text-gray-700">
            Alamat
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="kota" className="block font-medium text-gray-700">
            Kota
          </label>
          <input
            type="text"
            id="kota"
            name="kota"
            value={formData.kota}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="account" className="block font-medium text-gray-700">
            Nomor Rekening
          </label>
          <input
            type="text"
            id="account"
            name="account"
            value={formData.account}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="bank" className="block font-medium text-gray-700">
            Nama Bank
          </label>
          <input
            type="text"
            id="bank"
            name="bank"
            value={formData.bank}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="accountname"
            className="block font-medium text-gray-700">
            Rekening atas nama
          </label>
          <input
            type="text"
            id="accountname"
            name="accountname"
            value={formData.accountname}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="kontak" className="block font-medium text-gray-700">
            Kontak
          </label>
          <input
            type="text"
            id="kontak"
            name="kontak"
            value={formData.kontrak}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block font-medium text-gray-700">
            Email
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="other" className="block font-medium text-gray-700">
            Informasi Lainnya
          </label>
          <textarea
            id="other"
            name="other"
            value={formData.other}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
            rows="4"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="ktpImage" className="block font-medium text-gray-700">
            Upload KTP Image
          </label>
          <input
            type="file"
            id="ktpImage"
            name="ktpImage"
            onChange={handleImageUpload}
            className="mt-1"
            required
          />
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Tambahkan Mitra
        </button>

        <button
          type="button"
          onClick={handleCancelClick}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 ml-2">
          Batal
        </button>
      </form>
      {ktpImagePreview && (
        <div className="mb-4">
          <label className="block font-medium text-gray-700">
            Pratinjau KTP
          </label>
          <img
            src={ktpImagePreview}
            alt="KTP Preview"
            className="mt-2 max-w-xs"
          />
        </div>
      )}
    </div>
  );
};

export default TambahMitraForm;
