import React, { useState, useEffect } from "react";
import axios from "axios";
import EditKontrakModal from "./EditKontrakModal";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import numeral from "numeral";
import { API_URL } from "../../constants/apiConfig";

const DetailKontrak = ({ kontrak, onKontrakUpdated = () => {} }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedKontrak, setEditedKontrak] = useState(kontrak);
  const [showTrfModal, setShowTrfModal] = useState(false);
  const [showTrfOutModal, setShowTrfOutModal] = useState(false);
  const [imageHeight, setImageHeight] = useState(window.innerHeight * 0.75);

  useEffect(() => {
    setEditedKontrak(kontrak);
  }, [kontrak]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedKontrak((prevKontrak) => ({
      ...prevKontrak,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/ownstore/${kontrak.id}`,
        editedKontrak
      );
      if (response.status === 200) {
        setIsEditing(false);
        onKontrakUpdated();
      }
    } catch (error) {
      console.error("Gagal mengupdate kontrak:", error);
    }
  };

  const showTrfPhotoModal = () => {
    setShowTrfModal(true);
  };

  const hideTrfPhotoModal = () => {
    setShowTrfModal(false);
  };

  const showTrfOutPhotoModal = () => {
    setShowTrfOutModal(true);
  };

  const hideTrfOutPhotoModal = () => {
    setShowTrfOutModal(false);
  };

  const handleTrfInChange = async (e) => {
    const newTrfIn = e.target.files[0];
    if (newTrfIn) {
      try {
        const formData = new FormData();
        formData.append("newTrfIn", newTrfIn);
        const response = await axios.put(
          `${API_URL}/ownstore/update-trfIn/${kontrak.id}`,
          formData
        );
        if (response.status === 200) {
          // Refresh data kontrak setelah mengganti gambar trfIn
          onKontrakUpdated();
        }
      } catch (error) {
        console.error("Gagal mengganti gambar trfIn:", error);
      }
    }
  };

  const handleTrfOutChange = async (e) => {
    const newTrfOut = e.target.files[0];
    if (newTrfOut) {
      try {
        const formData = new FormData();
        formData.append("newTrfOut", newTrfOut);
        const response = await axios.put(
          `${API_URL}/ownstore/update-trfOut/${kontrak.id}`,
          formData
        );
        if (response.status === 200) {
          // Refresh data kontrak setelah mengganti gambar trfOut
          onKontrakUpdated();
        }
      } catch (error) {
        console.error("Gagal mengganti gambar trfOut:", error);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setImageHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shareViaWhatsApp = () => {
    const message = `id kontrak: ${kontrak.id}\nstore: ${
      kontrak.store
    }\nnama mitra: ${kontrak.name}\nkontrak: ${kontrak.kontrak}\njumlah slot: ${
      kontrak.slot
    }\ntanggal bergabung: ${
      kontrak.joindate
        ? new Date(kontrak.joindate)
            .toISOString()
            .substr(0, 10)
            .replace(/-/g, "/")
        : ""
    }\ntanggal berhenti: ${
      kontrak.outdate
        ? new Date(kontrak.outdate)
            .toISOString()
            .substr(0, 10)
            .replace(/-/g, "/")
        : ""
    }\nalasan:  ${kontrak.reason}\ninformasi lainnya: ${kontrak.information}`;
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  const shareViaTelegram = () => {
    const message = `id kontrak: ${kontrak.id}\nstore: ${
      kontrak.store
    }\nnama mitra: ${kontrak.name}\nkontrak: ${kontrak.kontrak}\njumlah slot: ${
      kontrak.slot
    }\ntanggal bergabung: ${
      kontrak.joindate
        ? new Date(kontrak.joindate)
            .toISOString()
            .substr(0, 10)
            .replace(/-/g, "/")
        : ""
    }\ntanggal berhenti: ${
      kontrak.outdate
        ? new Date(kontrak.outdate)
            .toISOString()
            .substr(0, 10)
            .replace(/-/g, "/")
        : ""
    }\nalasan:  ${kontrak.reason}\ninformasi lainnya: ${kontrak.information}`;
    const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(
      message
    )}`;
    window.open(telegramURL, "_blank");
  };

  const formattedInvestasi = numeral(kontrak.investasi).format("0,0.00");

  return (
    <div className="overflow-auto max-h-[calc(100vh-100px)] p-4 rounded-md max-w-screen-md mb-4 shadow-md">
      <div className="flex items-center justify-between mb-4">
        <h2 className="font-bold text-xl md:text-4xl">
          {" "}
          Detail <br className="md:hidden" />{" "}
          <span className="md:inline md:mr-2">&nbsp;</span>
          Data Mitra
        </h2>
        <button
          onClick={handleEditClick}
          className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600">
          Edit Kontrak
        </button>
      </div>

      {isEditing ? (
        <EditKontrakModal
          editedKontrak={editedKontrak}
          handleInputChange={handleInputChange}
          handleSaveClick={handleSaveClick}
          handleCancelEdit={handleCancelEdit}
        />
      ) : (
        <div className="mt-4 md:grid md:grid-cols-2 text-sm">
          <p>
            <strong>Kontrak:</strong> {kontrak.kontrak}
          </p>
          <p>
            <strong>Store:</strong> {kontrak.store}
          </p>
          <p>
            <strong>Mitra:</strong> {kontrak.name}
          </p>
          <p>
            <strong>Tanggal Gabung:</strong>{" "}
            {kontrak.joindate
              ? new Date(kontrak.joindate)
                  .toISOString()
                  .substr(0, 10)
                  .replace(/-/g, "/")
              : ""}
          </p>
          <p>
            <strong>Tanggal Berhenti:</strong>{" "}
            {kontrak.outdate
              ? new Date(kontrak.outdate)
                  .toISOString()
                  .substr(0, 10)
                  .replace(/-/g, "/")
              : ""}
          </p>
          <p>
            <strong>Alasan Berhenti:</strong> {kontrak.reason}
          </p>
          <p>
            <strong>Slot:</strong> {kontrak.slot}
          </p>
          <p>
            <strong>Investasi:</strong> Rp {formattedInvestasi}
          </p>
          <p className=" col-span-2">
            <strong>Info Lainnya:</strong> {kontrak.information}
          </p>
        </div>
      )}
      <div className="mt-4">
        <button
          onClick={showTrfPhotoModal}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 mb-4 md:mr-4">
          {" "}
          Tampilkan Foto Transfer Modal
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          onClick={() => document.getElementById("trfInput").click()}>
          Ganti Foto Transfer Modal
        </button>
        <input
          type="file"
          accept="image/*"
          onChange={handleTrfInChange}
          style={{ display: "none" }}
          id="trfInput"
        />
      </div>
      <div className="mt-4">
        <button
          onClick={showTrfOutPhotoModal}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 mb-4 md:mr-4">
          Foto Pencairan Modal
        </button>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          onClick={() => document.getElementById("trfOutput").click()}>
          Ganti Foto Pencairan Modal
        </button>
        <input
          type="file"
          accept="image/*"
          onChange={handleTrfOutChange}
          style={{ display: "none" }}
          id="trfOutput"
        />
      </div>
      <div className="flex mt-8 space-x-4">
        <button
          onClick={shareViaWhatsApp}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 flex items-center">
          <FaWhatsapp className="mr-2" /> Share
        </button>
        <button
          onClick={shareViaTelegram}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center">
          <FaTelegram className="mr-2" /> Share
        </button>
      </div>
      {showTrfModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-md max-w-md">
            <strong>Gambar Bukti Pembayaran:</strong>
            <img
              src={`${API_URL}/uploads/${kontrak.trfInUrl}`}
              alt="Foto Kontrak"
              className="mt-2 max-w-full"
              style={{ maxHeight: `${imageHeight}px` }}
            />
            <button
              onClick={hideTrfPhotoModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
              Tutup
            </button>
          </div>
        </div>
      )}
      {showTrfOutModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-md max-w-md">
            <strong>Gambar Bukti Pencairan:</strong>
            <img
              src={`${API_URL}/uploads/${kontrak.trfOutUrl}`}
              alt="Foto Pencairan"
              className="mt-2 max-w-full"
              style={{ maxHeight: `${imageHeight}px` }}
            />
            <button
              onClick={hideTrfOutPhotoModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
              Tutup
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailKontrak;
