import React, { useEffect } from "react";

const EditStoreModal = ({
  editedStore,
  handleInputChange,
  handleSaveClick,
  handleCancelEdit,
}) => {
  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (event.target.classList.contains("overlay")) {
        handleCancelEdit();
      }
    };

    document.addEventListener("click", handleOverlayClick);

    return () => {
      document.removeEventListener("click", handleOverlayClick);
    };
  }, [handleCancelEdit]);
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 overlay">
      <div className="bg-white p-6 rounded-md shadow-md grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <h2 className="font-bold text-xl mb-4">Edit Store</h2>
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Nama:</label>
          <input
            type="text"
            name="name"
            value={editedStore.name}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Opening:</label>
          <input
            type="date"
            name="opening"
            value={
              editedStore.opening
                ? new Date(editedStore.opening).toISOString().substr(0, 10)
                : ""
            }
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Alamat:</label>
          <input
            type="text"
            name="alamat"
            value={editedStore.alamat}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Kota:</label>
          <input
            type="text"
            name="kota"
            value={editedStore.kota}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-1 mb-4">
          <label className="block font-semibold">Investasi:</label>
          <input
            type="text"
            name="investasi"
            value={editedStore.investasi}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>

        <div className="col-span-2 mb-4">
          <label className="block font-semibold">Info Lainnya:</label>
          <input
            type="text"
            name="note"
            value={editedStore.note}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="col-span-2 justify-between flex">
          <button
            onClick={handleSaveClick}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
            Simpan
          </button>
          <button
            onClick={handleCancelEdit}
            className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-600">
            Batal
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditStoreModal;
