// DetailStoreModal.js
import React from "react";
import { FaTimes } from "react-icons/fa";
import DetailStorePage from "./detailStorePage";

const DetailStoreModal = ({ ownstore, onClose, onOwnStoreUpdated }) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="modal-close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <div className="modal-content">
          <DetailStorePage
            ownstore={ownstore}
            onclose={onClose}
            onOwnStoreUpdated={onOwnStoreUpdated}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailStoreModal;
