import React from "react";

const DeleteModal = ({ isOpen, onClose, onDelete }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-4 rounded-md shadow-md z-10">
        <h2 className="text-xl font-semibold mb-4">Delete Confirmation</h2>
        <p>Are you sure you want to delete this item?</p>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onDelete}
            className="bg-red-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-red-600">
            Delete
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
