import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../layouts/assets/logo512.png";

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const onToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    onLogout();
    navigate("/sign-in");
  };

  return (
    <>
      {/* Tampilkan tombol hamburger hanya pada layar kecil */}
      <div className="absolute top-4 right-4">
        <button
          onClick={onToggleSidebar}
          className="text-black"
          aria-label="Toggle Sidebar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {isSidebarOpen && (
        <div
          className="fixed top-0 left-0 z-40 w-full h-full bg-black bg-opacity-50"
          onClick={onToggleSidebar}></div>
      )}
      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-50 w-32 h-screen bg-gray-800 transform ${
          // Tambahkan kondisi media query CSS di bawah untuk menentukan kapan sidebar harus muncul
          isSidebarOpen
            ? "translate-x-0"
            : "-translate-x-full sm:translate-x-0 sm:block hidden"
        }`}
        aria-label="Sidebar">
        <div className="h-full px-3 py-4 overflow-y-auto">
          <div className="flex items-center justify-center">
            <img className="w-20" src={logo} alt="Logo" />
          </div>
          <ul className="p-4 space-y-2">
            <li className="hover:bg-gray-700 p-2 rounded text-gray-200">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="hover:bg-gray-700 p-2 rounded text-gray-200">
              <Link to="/input-transfer">Input Transfer</Link>
            </li>
            <li className="hover:bg-gray-700 p-2 rounded text-gray-200">
              <Link to="/list-mitra">Database Mitra</Link>
            </li>
            <li className="hover:bg-gray-700 p-2 rounded text-gray-200">
              <Link to="/list-store">Database Store</Link>
            </li>
            <li className="hover:bg-gray-700 p-2 rounded text-gray-200">
              <Link to="/list-kontrak">Database Kontrak</Link>
            </li>
            <li className="hover:bg-gray-700 p-2 rounded">
              <button
                onClick={handleLogout}
                className="text-gray-200 hover:underline cursor-pointer">
                Log Out
              </button>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
