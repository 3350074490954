// // components/layouts/Logout.jsx
// import React from "react";
// import { useNavigate } from "react-router-dom";

// const Logout = () => {
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     // Implement your logout logic here
//     // For example, clearing any user authentication token or data
//     // After logout, navigate back to the login page
//     navigate("/sign-in");
//   };

//   return (
//     <button
//       onClick={handleLogout}
//       className="text-blue-600 hover:underline cursor-pointer">
//       Logout
//     </button>
//   );
// };

// export default Logout;

import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Hapus data login dari local storage
    localStorage.removeItem("loggedIn"); // Sesuaikan dengan kunci yang Anda gunakan saat login

    // Setelah logout, navigate kembali ke halaman login
    navigate("/sign-in");
  };

  return (
    <button
      onClick={handleLogout}
      className="text-blue-600 hover:underline cursor-pointer">
      Logout
    </button>
  );
};

export default Logout;
