import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../layouts/assets/logo512.png";
import cover from "../layouts/assets/cover.JPG";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Cek local storage saat komponen dimuat
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn) {
      navigate("/"); // Redirect ke halaman utama jika sudah login sebelumnya
    }
  }, []);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Simulasi login dengan API
      const response = await fetch("https://api.crsmitra.cfd/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        // Simpan data login di local storage
        localStorage.setItem("loggedIn", "true");

        onLogin();
        navigate("/");
      } else {
        setLoginError(true);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <section
      className="h-screen flex items-center justify-center"
      style={{ backgroundImage: `url(${cover})`, backgroundSize: "cover" }}>
      <div className="w-full max-w-md bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <div className="flex items-center justify-center">
            <img className=" w-1/2 h-1/2" src={logo} alt="Logo" />
          </div>
          <h1 className=" text-center md:text-md font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            WEB APLIKASI DATABASE KEMITRAAN
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div className="">
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border bg-white placeholder-gray-400 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Username"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div className="">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border bg-white placeholder-gray-400 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>

            <div className=" bg-blue-500 rounded-lg hover:bg-blue-700">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Log in
              </button>
            </div>
            {/* Tambahkan kondisi untuk menampilkan pesan kesalahan */}
            {loginError && (
              <p className="text-red-500 text-sm text-center py-4">
                Username or password is incorrect.
              </p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
